<template>
	<v-card outlined :color="`green lighten-5`" class="h-100 pb-10 d-flex flex-column overflow-hidden">
		<v-card-title>
			<v-icon class="card-drag-handle">mdi-drag</v-icon>
			<v-icon class="mr-2" color="green">mdi-chart-areaspline</v-icon>
			<div class="my-auto fw-600 green--text text-uppercase fs-18">Active Lead Source</div>
			<v-spacer></v-spacer>
			<template v-if="['admin', 'manager', 'master'].includes(checkAdminOrManager())">
				<v-menu left bottom offset-y :max-width="200" :max-height="300" content-class="z-index-11">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							height="35"
							min-height="35"
							color="green lighten-4"
							class="mr-3"
							depressed
							v-bind="attrs"
							v-on="on"
						>
							<span class="fs-16 text-capitalize text-truncate" style="width: 120px">
								{{ dActiveAllLeadUser.display_name }}</span
							>
							<v-icon>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item
							v-for="(item, index) in usersList"
							:key="index"
							@click="filterByUser(item)"
							:class="[
								'py-1',
								{
									'grey lighten-3': JSON.stringify(dActiveAllLeadUser) == JSON.stringify(item),
								},
							]"
						>
							<v-list-item-avatar size="30" class="my-1 mr-2">
								<v-img
									:src="item.profile_img ? item.profile_img : $assetURL(`media/users/blank.png`)"
								></v-img>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title style="min-width: auto">{{ item.display_name }}</v-list-item-title>
								<v-list-item-subtitle class="mb-1 text-uppercase" v-if="item.total_project">
									<span class="fw-700">{{ item.total_project }}</span> project</v-list-item-subtitle
								>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
			<v-menu left offset-y :max-width="200" content-class="z-index-11">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						height="35"
						min-height="35"
						color="green lighten-4"
						class="mr-3"
						depressed
						v-bind="attrs"
						v-on="on"
					>
						<span class="fs-16 text-capitalize px-1">{{ dActiveAllLeadType.text }}</span>
						<v-icon>mdi-menu-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item
						v-for="(item, index) in filterProductTypeList"
						:key="index"
						@click="filterByType(item)"
						:class="[
							'py-1',
							{
								'grey lighten-3': dActiveAllLeadType.value == item.value,
							},
						]"
					>
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-menu left offset-y :max-width="200" content-class="z-index-11">
				<template v-slot:activator="{ on, attrs }">
					<v-btn height="35" min-height="35" color="green lighten-4" depressed v-bind="attrs" v-on="on">
						<span class="fs-16 text-capitalize px-1">{{ dActiveAllLeadDuration.text }}</span>
						<v-icon>mdi-menu-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item
						v-for="(item, index) in filterList"
						:key="index"
						@click="filterByDuration(item)"
						:class="[
							'py-1',
							{
								'grey lighten-3': dActiveAllLeadDuration.value == item.value,
							},
						]"
					>
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-card-title>
		<v-card-text class="flex-grow-1 position-relative">
			<v-img class="sale-symbol" :src="$assetURL('media/bg/leads.png')"></v-img>
			<v-img class="leads-symbol" :src="$assetURL('media/bg/leads-2.png')"></v-img>
			<div class="d-flex position-relative mt-2" style="gap: 4px">
				<!-- <v-btn-toggle v-model="activeStatus"> -->
				<v-slide-group show-arrows>
					<v-slide-item v-for="status in getStatusList" :key="status.value">
						<v-btn
							:outlined="status.value != activeStatus"
							dark
							height="35"
							min-height="35"
							depressed
							class="flex-grow-1 mx-1"
							:color="status.color"
							:value="status.value"
							@click="filterByStatus(status.value)"
						>
							{{ status.name }} ({{ status.total }})
						</v-btn>
					</v-slide-item>
				</v-slide-group>
				<!-- </v-btn-toggle> -->
				<div class="total-lead-count" :class="`${getTotalLead.color} lighten-4`">
					<h2 :class="`${getTotalLead.color}--text font-weight-bolder mb-0`" style="font-size: 2rem">
						<template v-if="countLoading">
							<v-progress-circular indeterminate size="20" width="3" color="teal"></v-progress-circular>
						</template>
						<template v-else> {{ getTotalLead.total }} </template>
					</h2>
					<p :class="`${getTotalLead.color}--text`">Total</p>
				</div>
			</div>
			<div class="d-flex flex-wrap align-center justify-center h-100">
				<div class="d-flex flex-wrap align-center justify-center mt-5">
					<template v-if="countLoading">
						<div style="height: 226px; width: 400px" class="d-flex align-center justify-center">
							<v-progress-circular indeterminate size="60" width="6" color="cyan"></v-progress-circular>
						</div>
					</template>
					<template v-else>
						<apexchart
							type="donut"
							:options="getChartOptions"
							:series="getSeries"
							width="400"
						></apexchart>
					</template>
					<div class="flex-grow-1" style="max-width: 200px">
						<div
							class="border-bottom-dotted py-1 px-1"
							v-for="(row, index) in getSourceList"
							:key="index"
						>
							<div class="fw-500 fs-16 d-flex align-center text-right">
								<v-spacer></v-spacer>
								<span :class="`${row.color}--texttt text-truncate`">
									{{ row.name }}
								</span>
								<div style="min-width: 50px">
									<v-chip
										style="min-width: 32px; min-height: 32px"
										medium
										class="pa-1 d-inline-flex justify-center"
										:color="row.color"
									>
										<template v-if="countLoading">
											<v-progress-circular
												indeterminate
												size="14"
												width="2"
												color="white"
											></v-progress-circular>
										</template>
										<template v-else>
											<span class="fs-18 white--text">
												{{ row.total }}
											</span>
										</template>
									</v-chip>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>
<script>
import { GET_ALL_LEADS, SET_ACTIVE_FILTER } from "@/core/services/store/dashboard.module.js";
import { mapGetters } from "vuex";
import { filter } from "lodash";
export default {
	name: "ProjectStatus",
	data() {
		return {
			countLoading: false,
			filterList: [
				{ text: "This Month", value: "this_month" },
				{ text: "Last Month", value: "last_month" },
				{ text: "This Quarter", value: "this_quarter" },
				{ text: "Last Quarter", value: "last_quarter" },
				{ text: "This Year", value: "this_year" },
				{ text: "Last Year", value: "last_year" },
			],
			filterProductTypeList: [
				{ text: "All type", value: "all" },
				{ text: "Software", value: "software" },
				{ text: "Seo", value: "seo" },
			],
			series: [
				{
					name: "series-a",
					data: [13, 12, 33, 53, 65],
					color: "#EE6D7A",
				},
			],
			activeStatus: "all",
			statusList: [
				{
					color: "blue",
					name: "All",
					value: "all",
					key: "allLeads",
				},
				{
					color: "cyan",
					name: "Follow Up",
					value: "follow-up",
					key: "follow_up_leads",
				},
				{
					color: "orange",
					name: "Not Contacted",
					value: "not-contacted",
					key: "not_contacted_leads",
				},
				{
					color: "brown",
					name: "Meeting",
					value: "meeting",
					key: "meeting_leads",
				},
				{
					color: "blue-grey",
					name: "Quotation",
					value: "quotation",
					key: "quotation_leads",
				},
				/* {
					color: "green",
					name: "Accepted",
					value:'accept',
					key:'accept_leads'
				}, */
				{
					color: "red",
					name: "Rejected",
					value: "reject",
					key: "reject_leads",
				},
			],
			graphs: [
				{
					color: "orange",
					name: "Web Enquiry",
					key: "web_enquiry_count",
				},
				{
					color: "cyan",
					name: "Web Chat",
					key: "web_chat_count",
				},
				{
					color: "brown",
					name: "Email Marketer",
					key: "email_marketer_count",
				},
				{
					color: "light-blue",
					name: "Tele Call",
					key: "tele_call_count",
				},
				{
					color: "red",
					name: "Google Adwords",
					key: "google_adwords_count",
				},
				{
					color: "pink",
					name: "FSM/AMS (Brochure Lead Form)",
					key: "fsm_ams_count",
				},
				{
					color: "purple",
					name: "Facebook",
					key: "facebook_count",
				},
				{
					color: "amber",
					name: "Referral",
					key: "referral_count",
				},
				{
					color: "blue-grey",
					name: "Intern",
					key: "intern_count",
				},
				{
					color: "amber",
					name: "Canvassing",
					key: "canvassing_team_count",
				},
				{
					color: "grey",
					name: "None",
					key: "none_count",
				},
			],
		};
	},
	methods: {
		hexColor(color) {
			switch (color) {
				case "red":
					return "#F44336";
				case "orange":
					return "#FF9800";
				case "cyan":
					return "#00BCD4";
				case "brown":
					return "#795548";
				case "light-blue":
					return "#03A9F4";
				case "teal":
					return "#009688";
				case "green":
					return "#4CAF50";
				case "blue-grey":
					return "#607D8B";
				case "indigo":
					return "#3F51B5";
				case "amber":
					return "#FFC107";
				case "purple":
					return "#9C27B0";
				case "pink":
					return "#E91E63";
				case "grey":
					return "#e0e0e0";
			}
		},
		filterByStatus(item) {
			if (this.activeStatus != item) {
				this.activeStatus = item;
				this.getAllLeads();
			}
		},
		filterByDuration(item) {
			if (this.dActiveAllLeadDuration.value != item.value) {
				this.$store.commit(SET_ACTIVE_FILTER, {
					key: "active_all_lead_duration",
					value: item,
				});
				this.getAllLeads();
			}
		},
		filterByType(item) {
			if (this.dActiveAllLeadType.value != item.value) {
				this.$store.commit(SET_ACTIVE_FILTER, {
					key: "active_all_lead_type",
					value: item,
				});
				this.getAllLeads();
			}
		},
		filterByUser(item) {
			if (this.dActiveAllLeadUser.id != item.id) {
				this.$store.commit(SET_ACTIVE_FILTER, { key: "active_all_lead_user", value: item });
				this.getAllLeads();
			}
		},
		getAllLeads() {
			this.countLoading = true;
			this.$store
				.dispatch(GET_ALL_LEADS, {
					status: this.activeStatus,
					product_type: this.dActiveAllLeadType.value,
					filter: this.dActiveAllLeadDuration.value,
					user_filter: this.dActiveAllLeadUser.id ? this.dActiveAllLeadUser.id : null,
				})
				.finally(() => {
					this.countLoading = false;
				});
		},
	},
	computed: {
		...mapGetters([
			"localDB",
			"dAllLeads",
			"dActiveAllLeadDuration",
			"dActiveAllLeadType",
			"dActiveAllLeadUser",
		]),
		usersList() {
			let _users = this.localDB("allUsers", []);
			_users = filter(_users, (user) => user.users_type == "sales");
			_users.unshift({
				id: 0,
				display_name: "All Users",
				profile_img: this.$assetURL("media/users/blank.png"),
				total_project: 0,
			});
			return _users;
		},
		getSourceList() {
			const _all_leads = this.dAllLeads;
			return this.graphs.map((row) => {
				return {
					...row,
					total: _all_leads[row.key] ? _all_leads[row.key] : 0,
				};
			});
		},
		getStatusList() {
			const _all_leads = this.dAllLeads;
			return this.statusList.map((row) => {
				return {
					...row,
					total: _all_leads[row.key] ? _all_leads[row.key] : 0,
				};
			});
		},
		getSeries() {
			const _all_leads = this.dAllLeads;
			return this.graphs.map((row) => {
				return _all_leads[row.key] ? _all_leads[row.key] : 0;
			});
		},
		getTotalLead() {
			const _all_leads = this.dAllLeads;
			let total = 0;
			let color = "teal";
			this.graphs.map((row) => {
				total = total + (_all_leads[row.key] ? _all_leads[row.key] : 0);
				return row;
			});
			const active_status = this.statusList.find((row) => row.value == this.activeStatus);
			if (active_status) {
				color = active_status.color;
			}
			return { total, color };
		},
		getTotalCost() {
			const _all_leads = this.dAllLeads;
			return _all_leads.project_cost ? _all_leads.project_cost : 0;
		},
		getChartOptions() {
			const _labels = this.graphs.map((row) => {
				return row.name;
			});
			const _colors = this.graphs.map((row) => this.hexColor(row.color));
			return {
				chart: {
					type: "donut",
					width: 400,
				},
				legend: {
					position: "bottom",
					show: false,
				},
				colors: _colors,
				labels: _labels,
			};
		},
	},
	/* mounted() {
		const options = cloneDeep(this.chartOptions);
		this.chartOptions = { ...options, colors: this.getColors, labels: this.getLabels };
	}, */
};
</script>
<style lang="scss" scoped>
.total-lead-count {
	position: absolute;
	top: calc(100% + 50px);
	left: 40px;
	height: 95px;
	width: 95px;
	border: 3px solid #b7cfb9;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
.sale-symbol {
	height: 200px;
	width: 300px;
	position: absolute;
	bottom: -30px;
	left: -30px;
	opacity: 0.1;
}
.leads-symbol {
	height: 300px;
	width: 300px;
	position: absolute;
	bottom: 50px;
	right: -50px;
	opacity: 0.1;
}
</style>
